
import { useSearchParams } from "react-router-dom"
import Calls from "./call"





export default function MechCall(){
    const [searchParams,setSearchParams] = useSearchParams();
    return <div>
                <Calls carModel={searchParams.get("model")} customersEmail={searchParams.get("email")}
                customersName={searchParams.get("name")} id={searchParams.get("id")}
                profile_pic={searchParams.get("image")}/>
    </div>
}