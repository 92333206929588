import axios from "axios";
import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import styled from "styled-components";

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 
export const Request = ({ requests, fetchUserRequests }) => {
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  console.log(requests);

  const accessToken = localStorage.getItem("authAccessToken");

  const updateRequestStatus = async (requestId, status, index) => {
    setLoading(true);
    setCurrentIndex(index);
    try {
      const response = await axios.put(
        `${baseUrl}/client/update-mechanic-request-status/`,
        { request_status_id: requestId, new_status: status },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      fetchUserRequests();
      setLoading(false);
      setCurrentIndex(-1);
      if (status === "accepted") {
        toast("You just accepted a request");
      } else {
        toast("You just rejected a request");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setCurrentIndex(-1);
    }
  };

  return (
    <>
      {requests?.map((request, i) => {
        const req = request.request;

        return (
          <RequestView key={req?.id}>
            <div className="request-info">
              <div className="info">
                <div className="img-wrapper">
                  <img
                    src={req?.user?.profile_pic}
                    alt="mechanic"
                  />
                </div>
                <div className="text">
                  <p className="bold">
                    {req?.user?.first_name} {req?.user?.last_name}
                  </p>
                  <p className="subtitle">{req?.car_brand}</p>
                </div>
              </div>

              <p className="distance">{req?.location}</p>
            </div>

            <p className="detail">{req?.description}</p>

            <hr className="line" />

            <div className="actions">
              <button
                onClick={() => {
                updateRequestStatus(request?.id.toString(), "accepted", i)
              }}
              >
                {loading && currentIndex === i ? (
                  <Oval
                    color="#FFFFFF"
                    height={20}
                    width={20}
                    strokeWidth={3}
                  />
                ) : (
                  'Accept'
                )}
              </button>

              <button
                className="reject"
                onClick={() =>
                  updateRequestStatus(request?.id.toString(), "rejected", i)
                }
              >
                {loading && currentIndex === i ?  (
                  <Oval
                    color="#FFFFFF"
                    height={20}
                    width={20}
                    strokeWidth={3}
                  />
                ) : (
                  "Reject"
                )}
              </button>
            </div>
          </RequestView>
        );
      })}
    </>
  );
};

const RequestView = styled.div`
  display: flex;
  padding: 1.2rem;
  margin: 1rem 0;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #d9d9d9;

  .request-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .img-wrapper {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .bold {
          font-weight: 600;
          font-size: 0.8rem;
        }

        .subtitle {
          font-size: 0.8rem;
          color: #878c98;
        }
      }
    }

    .distance {
      width: 40%;
      word-wrap: break-word;
      color: #878c98;
      font-size: 14px;
      text-align: end;
      line-height: 15px;
    }
  }

  .line {
    border-top: 1px solid #d9d9d9;
  }

  .detail {
    margin: 1.5rem 0;
    line-height: 17px;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-top: 1.5rem;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      border-radius: 5px;
      padding: 0.45rem 2rem;
      color: #ffffff;
      background-color: #1eb85f;
    }

    .reject {
      color: #000000;
      background: none;
      border: 1px solid #b81e1e;
    }
  }
`;
