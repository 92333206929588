
import { HambergerMenu,Profile,Edit,Wallet,Message,Clipboard,Security,Logout } from "iconsax-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useRef } from "react";
import { useSwipeable } from "react-swipeable";

export default function Menu({name,car,image}){
    const[showMenuBody,setShowMenuBody] = useState(false);
    const handle = useSwipeable({
        onSwipedLeft:(eventData)=>{ console.log("swipe left"); openMenu()},
        onSwipedRight:(eventData)=>{ console.log("swipe left"); closeMenu()},
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    })
    
    function openMenu(){
        setShowMenuBody(init=>true)
    }
    
    function closeMenu(){
        setShowMenuBody(init=>false)
    }

    return <div className="absolute top-0 left-0 z-20 w-0 h-0">
                <MenuIcon handleClick={openMenu}/>
                <MenuBody image={image} closeMenu={closeMenu} slideMenu={showMenuBody} name={name ||"user"} car={car} links={[{title:"Wallet",icon:Wallet,url:"/wallet"},{title:"Get Help",icon:Message,url:"/user-help"},{title:"Legal",icon:Clipboard,url:"/legal"},{title:"Privacy Policy",icon:Security,url:"/"},{title:"About us",icon:Profile,url:"/"},{title:"Logout",icon:Logout,url:"/"}]}/>
    </div>
}

function MenuIcon({handleClick}){
    return <div onClick={handleClick} className="absolute top-6 left-6 bg-slate-200 rounded-full p-2 ">
                <HambergerMenu className="text-black"/>
    </div>
}

function MenuBody({links=[],name,car,slideMenu,closeMenu,image}){
    const bodyRef = useRef(null)
    const handle = useSwipeable({
        // onSwipedLeft:(eventData)=>{ console.log("swipe left"); openMenu()},
        onSwipedUp:(eventData)=>{console.log("swiping");},
        onSwipedRight:(eventData)=>{ console.log("swipe left"); closeMenu()},
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true,
        onTap:()=>{console.log("tabbeed");}
    })
    const slideAnimation = {
        init:{
            left:"-101vw"
        },
        slideOut:{
            left:"0vw",
            transition:{
                duration:1
            }
        },
        slideIn:{
            left:"-101vw",
            transition:{
                duration:1
            }
        }
    }

function handleClick(e){
    e.currentTarget == e.target && closeMenu()
}

    return <motion.div  onClickCapture={(e)=>handleClick(e)}  variants={slideAnimation} initial="init" animate ={slideMenu?"slideOut":"slideIn"} className="top-0 left-0 w-screen h-screen  relative">
                <div onClick={(e)=>{e.stopPropagation()}} className="  w-[85%] h-screen bg-slate-200 flex flex-col gap-8">
                <div className="flex w-full justify-between px-4 py-8 items-center">
                        <div className="flex gap-4 ">
                            <div className={`w-[15vw] h-[15vw] ${image === null && "bg-teal-500 p-1"}  overflow-hidden rounded-full  box-border  `}>
                                {image !== null ? <img className="" src={image}/> : <Profile variant="Bulk" className="text-slate-600"/>}
                            </div>
                            <div>
                                <p className="font-semibold">{name}</p>
                                {!String(car).includes("null") && <p className="text-sm">{car}</p>}
                            </div>
                        </div>
                        <Link to={"/profile"}>
                        <Edit/>
                        </Link>
                    </div >
                        {
                            links.map(function(ALink){
                                return <Link className="flex gap-4 px-4 font-semibold" to={ALink.url}><ALink.icon/>{ALink.title}</Link>
                            })
                        }
                </div>
    </motion.div>
}