

import { useEffect,  useState } from "react";
import axios from "axios";
import { CloseCircle, CallCalling,Video} from "iconsax-react";
import { Alert, Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Damp from "./damp";
import MatchedMechanic from "./matchMechanic";

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export default function InstantMatchFound({calldata,image,name,isOnline,expertise,showScreen,showLowBlance,showDamp,id}){
    const [showAlert,setShowAlert] = useState(false);
    const [alertState,setAlertState] = useState("info");
    const [deviceToken,setdeviceToken] = useState();
    const navigate = useNavigate()
    
    function handleCancel(){
        const accessToken = localStorage.getItem('authAccessToken');
    if (accessToken) {
        axios.delete(
            `${baseUrl}/client/user_cancel-request/${id}/` ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
              })
              .then((response) => {
                setShowAlert(init =>true);
                setAlertState(init=>"info");
                setTimeout(() => {
                    showScreen(init=>false)
                }, 2500);
                  console.log("cancel request response",response);
              })
              .catch((error) => {
                setShowAlert(init =>true)
                setAlertState(init=>"error")
                setTimeout(() => {
                    showScreen(init=>false)
                }, 2500);
                console.error('Error deleting data:', error);
              });
          }else {
            console.error('Authentication credentials were not provided.');
          }
    }

    function showLowBalanceDialog(){
        console.log("showing low balance");
        showLowBlance(init=>true)
    }
   async function isBalanceInsufficient(){
        const minimumBalance = 500;
        let insufficientBalance 
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
            try{
                insufficientBalance = axios.get(`${baseUrl}/wallets/balance/`,
                {
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    if(Number(response.data.balance) < minimumBalance){
                        console.log("data balance is gerater than balance is:",Number(response.data.balance) < minimumBalance,"because balance =",Number(response.data.balance))
                        return true
                    }
                    return false
                }).catch(function(error){
                    console.log(error);
                })
            }catch(error){
                console.log("an error occured while fetching profile data")
            }
        }
        return insufficientBalance
    }
    async function handleVideoCall(){
        // const isInsufficient =  false
        const isInsufficient =  await isBalanceInsufficient();
        if (!isInsufficient) {
                navigate(`/call?email=${calldata?.email}&name=${name}&mechanic_id=${calldata?.mechanic_id}&token=${calldata?.token}&model=${calldata?.model}&image=${image}&cllrtkn=${deviceToken}`)
                // navigate(`/call?email=${calldata?.email}&name=${name}&mechanic_id=${calldata?.mechanic_id}&token=${calldata?.token}&model=${calldata?.model}&image=${image}`)
        }else{
            showLowBalanceDialog();
        }
    }

    async function handleAudioCall(){
        const isInsufficient =  await isBalanceInsufficient()
        // const isInsufficient = false
        if (!isInsufficient) {
                navigate(`/audio-call?email=${calldata?.email}&name=${name}&mechanic_id=${calldata?.mechanic_id}&token=${calldata?.token}&model=${calldata?.model}&image=${image}&cllrtkn=${deviceToken}`)
        }else{
            showLowBalanceDialog();
        }
    }

    useEffect(function(){
        setTimeout(() => {
            setShowAlert(init=>false)
        }, 2000);
    },[showAlert])

    useEffect(function(){
        const device_Token = localStorage.getItem("deviceToken");
        setdeviceToken(init=>device_Token)
    })

    return <div className="z-[3] w-full px-8 py-4 absolute bottom-0 left-0 rounded-2xl bg-white flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <p className="font-bold text-2xl">We've found a match for you!</p>
                        <p className="text-slate-600"> Rely on our AI powered system to determine the nearest available mechanic</p>
                    </div>
                    <MatchedMechanic image={image} name={name} isOnline={isOnline} expertise={expertise} />
                    <div className="flex  w-full justify-between">
                        <Button onClick={handleAudioCall} autoCapitalize={false} sx={{textTransform:"none"}}  variant="contained" style={{backgroundColor:"rgb(22 163 74)",fontSize:"1rem"}} className="bg-green-600 text-white flex gap-2 p-3 w-[47%]"><CallCalling variant="Bold" /> Phone Call</Button>
                        {/* <Tooltip title="ComingSoon" arrow> */}
                            <Button onClick= {handleVideoCall}  sx={{textTransform:"none"}} className="flex gap-2 p-3 w-[47%]" style={{backgroundColor:"rgb(203 213 225)",color:"black",fontSize:"1rem"}} ><Video variant="TwoTone"/>Video Call</Button>
                        {/* </Tooltip> */}
                    </div>
                    <div className="flex gap-2" onClick={()=>handleCancel()}>
                        <CloseCircle className="text-orange-700"/>
                        <p>Cancel Request</p>
                    </div>
                    <div>{showDamp && <Damp/>}</div>
                    {showAlert && <div className="absolute top-[30vh]" ><Alert severity={alertState} style={{fontFamily:"SharpSans"}}>{alertState ==="info"?"Request Successfully cancled":"Error encountered while cancelling request"}</Alert></div>}
                    {/* {showAlert && <div className="absolute top-[-30vh]" ><Alert severity="error" style={{fontFamily:"SharpSans"}}>Request Successfully cancled</Alert></div>} */}

            </div>
}