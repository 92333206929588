import { useDispatch, useSelector } from "react-redux";
import { LoaderKey } from "../../Components/loaderKey";
import { useEffect, useState } from "react";
import { TextError } from "../../Components/utils";
import ProgressBar from "@ramonak/react-progress-bar"; //the green progress bar
import Select from "react-select";
import SelectField from "../../Components/input/Select";
import { Link } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormikControl from "../../Components/formik/formikControl";
import * as Yup from "yup";
import Button from "../../Components/button";
import { FaPlus } from "react-icons/fa6";
import CreatableSelect from "react-select/creatable";
import {
  carOwnerCreateAccountStep3,
  getCarBrands,
} from "../../Components/store/actions/userAction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CarOwnerCreateAccount3 = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [accessToken, setAccessToken] = useState();
  const [selectedCarBrand, setselectedCarBrand] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customStyles = {
    option: (customStyles, state) => ({
      // You can log the defaultStyles and state for inspection
      // You don't need to spread the defaultStyles
      ...customStyles,
      color: "#4D4D4D",
      backgroundColor: "#F9F8F8",
    }),

    control: (customStyles) => ({
      ...customStyles,
      // Notice how these are all CSS properties
      backgroundColor: "",
      padding: "5px",
    }),
    multiValueLabel: (customStyles) => ({
      ...customStyles,
      backgroundColor: "#F0FFFA",
      fontWeight: "600px",
    }),
    multiValueRemove: (customStyles) => ({
      ...customStyles,
      backgroundColor: "#F0FFFA", // Change this to your desired text color for selected options
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),
  };

  const initialValues = {
    car_brand_id: null,
    car_model: "",
  };
  const validationSchema = Yup.object({
    car_brand_id: Yup.string().required("Required"),
    car_model: Yup.string().required("Required"),
  });

  useEffect(() => {
    dispatch(getCarBrands());

    // get the access token from the local storage
    const access = localStorage.getItem("authAccessToken");
    setAccessToken(access);
  }, [dispatch]);

  const carBrands = useSelector((state) => state.user.carBrands);
  const loading = useSelector((state) => state.app);

  // to prevent more than 5 cars from being selected
  // const handleSelectChange = (selectedOptions, field, form) => {
  //   if (selectedOptions.length <= 5) {
  //     form.setFieldValue(field.name, selectedOptions);
  //   }
  // };

  const options = carBrands.map((brand) => ({
    value: brand.id,
    label: brand.name,
  }));

  const handleClick = (formik) => {
    const selectedBrand = selectedCarBrand ? selectedCarBrand.label : "None";
    const toastMessage = `${selectedBrand} ${formik.values.car_model} added`;
    toast(toastMessage);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      // console.log(values);

      const parameters = {
        data: {
          ...values,
        },
        accessToken: accessToken,
      };
      const res = await dispatch(carOwnerCreateAccountStep3(parameters));
      if (res && res.payload && res.payload.success === true) {
        // console.log("mechanic account step3 has been successfully created");
        navigate("/login-user");
      }
    } catch (err) {
      console.error("Create Account failed!:", err);
      setErrorMessage("Error");
      // Log error message
    } finally {
      setSubmitting(false); // Reset submitting state in Formik
    }
  };

  //   console.log(selectedCarBrand);

  //   console.log(selectedCarBrand);

  return (
    <div className="sharp-sans flex flex-col justify-center items-center">
      <div className="mt-7">
        <div className="space-y-1">
          <h3 className="sharp-sans-bold text-2xl">Create Account</h3>
          <p className="sharp-sans text-xs font-medium text-[#878C98]">
            Your journey to accessing and connecting with certified
            <br /> professional mechanics begins here
          </p>
        </div>

        <div className="pt-4 space-y-2">
          <div className="flex justify-between">
            <p className="sharp-sans-bold text-base">
              Enter your professional details
            </p>
            <p className="text-xs text-[#989DB3] font-semibold pt-1">
              Step 3 of 3
            </p>
          </div>
          <div className="">
            <ProgressBar
              completed={100}
              bgColor="#1EB85F"
              height="5px"
              customLabel=" "
              maxCompleted={100}
            />
          </div>
        </div>

        <div className="mt-6">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form className=" flex flex-col justify-between h-[490px]">
                  <div className="space-y-[70px]">
                    <div className="">
                      <p className="font-bold sharp-sans text-[14px] pb-1">
                        Car Brand
                      </p>
                      <Field name="car_brand_id">
                        {({ field, form }) => (
                          <Select
                            {...field}
                            options={options}
                            placeholder="Select car brand"
                            styles={customStyles}
                            onChange={(selectedOptions) => {
                              // console.log(selectedOptions);
                              setselectedCarBrand(selectedOptions);
                              //   handleSelectChange(selectedOptions, field, form);
                              form.setFieldValue(
                                field.name,
                                selectedOptions ? selectedOptions.value : null
                              );
                            }}
                            value={selectedCarBrand}
                            isSearchable={true}
                            className="!max-w-[330px] max-h-[44px] min-h-[44px]"
                          />
                        )}
                      </Field>
                      <ErrorMessage name="car_brand_id" component={TextError} />
                    </div>
                    <div className="">
                      <FormikControl
                        control="input"
                        type="text"
                        label="Car Model"
                        name="car_model"
                        placeholder="Input car model"
                        className="!min-w-[330px]"
                      />
                      <Button
                        type="button"
                        onClick={() => {
                          handleClick(formik);
                        }}
                        className="mt-4 gap-x-[2px] text-xs flex py-3 px-2 !h-[10px] !min-w-[20px] rounded-[22px] bg-[#84FDD4]"
                      >
                        <FaPlus />
                        <p>Add</p>
                      </Button>
                    </div>
                  </div>

                  {/* <ErrorMessage name="terms" component={TextError} /> */}
                  {errorMessage && <TextError>{errorMessage}</TextError>}

                  <div className="pb-5">
                    <Button
                      className="bg-greeen text-white !mt-[30px]"
                      type="submit"
                      disable={!formik.isValid || formik.isSubmitting}
                      loading={loading[LoaderKey.CAR_OWNER_CREATE_ACCOUNT_STEP3]?.loading}
                    >
                      {formik.isSubmitting ? "Creating..." : "Continue"}
                    </Button>
                    <Link
                      to="/login-user"
                      className="pt-2 text-center flex justify-center font-[500] text-xs"
                    >
                      Already have an account?{" "}
                      <span className="pl-1 text-greeen sharp-sans-bold">
                        Log In
                      </span>
                    </Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CarOwnerCreateAccount3;
