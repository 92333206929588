import { useDispatch, useSelector } from "react-redux";
import { LoaderKey } from "../../Components/loaderKey";
import { useEffect, useState } from "react";
import { TextError } from "../../Components/utils";
import ProgressBar from "@ramonak/react-progress-bar"; //the green progress bar
import Select from "react-select";
import SelectField from "../../Components/input/Select";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormikControl from "../../Components/formik/formikControl";
import * as Yup from "yup";
import Button from "../../Components/button";
import FileInput from "../../Components/input/fileInput";
import { getCarBrands, getMechanicServices } from "../../Components/store/actions/userAction";
import { mechanicCreateAccountStep3,updateProfilePicture } from "../../Components/store/actions/userAction";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import imageCompression from "browser-image-compression";
import Compressor from 'compressorjs';
import AdressOptions from "./adressOptions";


const MechanicCreateAccount3 = () => {
  const customStyles = {
    option: (customStyles, state) => ({
      // You can log the defaultStyles and state for inspection
      // You don't need to spread the defaultStyles
      ...customStyles,
      color: "#4D4D4D",
      backgroundColor: "#F9F8F8",
    }),

    control: (customStyles) => ({
      ...customStyles,
      // Notice how these are all CSS properties
      backgroundColor: "",
      padding: "5px",
    }),
    multiValueLabel: (customStyles) => ({
      ...customStyles,
      backgroundColor: "#F0FFFA",
      fontWeight: "600px",
    }),
    multiValueRemove: (customStyles) => ({
      ...customStyles,
      backgroundColor: "#F0FFFA", // Change this to your desired text color for selected options
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "000" , fontSize: '14px'}),
    placeholder: (customStyles) => ({
      ...customStyles,
      fontSize: '12px', // Adjust the font size as needed
    }),
  };

  const FILE_EXTENSIONS = ["image/jpeg", "image/png", "image/jpg", "image/gif"];

  const initialValues = {
    education_level: "",
    car_speciality_ids: [],
    workshop_location: "",
    supported_document: null,
    profile_pic: null,
    services_id: null,
  };

  const validationSchema = Yup.object({
    education_level: Yup.string().required("Required"),
    car_speciality_ids: Yup.array()
      .min(1, "At least one car specialty is required")
      .max(5, "You can only select up to 5 specialties")
      .required("Required"),
    workshop_location: Yup.string().required("Required"),
    supported_document: Yup.mixed()
    .required("Required")
    .test(
      "fileType",
      "Unsupported file format. Only image files are allowed.",
      (value) => {
        return value && FILE_EXTENSIONS.includes(value.type);
      }
    ),
    profile_pic: Yup.mixed()
      .test('fileType', "Unsupported file format. Only image files are allowed", value => !value || (value && FILE_EXTENSIONS.includes(value.type)))
      .nullable(),
  });

  const educationLevel = Object.freeze([
    { key: "Select Educational level", value: "", $isDisabled: true },
    { key: "Secondary School Education", value: "secondary_school_education" },
    { key: "University Degree", value: "university_degree" },
    { key: "Vocational Skill or Technical Certificate", value: "vocational_skill_technical_certificate" },
  ]);

  const [raiseInput, setRaiseInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [accessToken, setAccessToken] = useState();
  const [selectedService, setSelectedService] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCarBrands());
    

    // get the access token from the local storage
    const access = localStorage.getItem("authAccessToken");
    setAccessToken(access);
  }, [dispatch]);
  useEffect(() =>{
    dispatch(getMechanicServices());
  }, [dispatch])

  const carBrands = useSelector((state) => state.user.carBrands);
  const mechanicServices = useSelector((state) => state.user.mechanicServices);
  const loading = useSelector((state) => state.app);

  // to prevent more than 5 cars from being selected
  const handleSelectChange = (selectedOptions, field, form) => {
    if (selectedOptions.length <= 5) {
      form.setFieldValue(field.name, selectedOptions);
    }
  };

  //compressing the image of the profile_pic
  const handleImageUpload = async (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          // Preserve the file extension
          const newFile = new File([result], file.name, {
            type: result.type,
          });
          resolve(newFile);
        },
        error(err) {
          // console.error('Image compression error:', err);
          // setErrorMessage('Image compression failed, please try again.');
          reject(err);
        },
      });
    });
  };

  const options = carBrands.map((brand) => ({
    value: brand.id,
    label: brand.name,
  }));
  // console.log(carBrands)

  const services = mechanicServices.map((service) => ({
    value: service.id,
    label: service.name,
  }))

  const onSubmit = async (values, { setSubmitting }) => {
    try {

      const parameters = {
        data: {
          education_level: values.education_level,
          car_speciality_ids: values.car_speciality_ids,
          workshop_location: values.workshop_location,
          supported_document: await handleImageUpload(values.supported_document),
          services_id: values.services_id
        },
        accessToken: accessToken,
      };
      //submitting the profile_pic to a different endpoint 
      if (values.profile_pic) {
        // const compressedProfilePic = await handleImageUpload(values.profile_pic);
        const profilePicParameters = {
          data: {
            profile_pic: await handleImageUpload(values.profile_pic),
          },
          accessToken: accessToken,
        };
        await dispatch(updateProfilePicture(profilePicParameters));
      }

      const res = await dispatch(mechanicCreateAccountStep3(parameters));
      if (res.payload.success === true) {
        // console.log("mechanic account step3 has been successfully created");
        navigate("/success-verification");
      }
    } catch (err) {
      console.error("Create Account failed!:", err);
      setErrorMessage("Create account failed, please contact support for assistance");
      // Log error message
    } finally {
      setSubmitting(false); // Reset submitting state in Formik
    }
  };

  return (
    <div className="sharp-sans flex flex-col justify-center items-center">
      <div className="mt-7">
        <div className="space-y-1">
          <h3 className="sharp-sans-bold text-2xl">Create Account</h3>
          <p className="sharp-sans text-xs font-medium text-[#878C98]">
            Your journey to accessing and connecting with certified
            <br /> professional mechanics begins here
          </p>
        </div>

        <div className="pt-4 space-y-2">
          <div className="flex justify-between">
            <p className="sharp-sans-bold text-base">
              Enter your professional details
            </p>
            <p className="text-xs text-[#989DB3] font-semibold pt-1">
              Step 3 of 3
            </p>
          </div>
          <div className="">
            <ProgressBar
              completed={100}
              bgColor="#1EB85F"
              height="5px"
              customLabel=" "
              maxCompleted={100}
            />
          </div>
        </div>

        <div className="mt-6">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form className=" flex flex-col justify-between h-[490px]">
                  <div className="space-y-[65px]">
                    <div className="">
                      <p className="font-bold sharp-sans text-[14px] pb-1">
                        Car Specialty
                      </p>
                      <Field name="car_speciality_ids">
                        {({ field, form }) => (
                          <Select
                            {...field}
                            options={options}
                            placeholder="You can select up to 5 car specialties"
                            isMulti
                            styles={customStyles}
                            onChange={(selectedOptions) =>
                              handleSelectChange(selectedOptions, field, form)
                            }
                            isSearchable={true}
                            className="!max-w-[330px] max-h-[44px] min-h-[44px]"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="car_speciality_ids"
                        component={TextError}
                      />
                    </div>
                    <FormikControl
                      control="select"
                      label="Specify Educational Level"
                      options={educationLevel}
                      defaultValue=""
                      name="education_level"
                      className="max-w-[330px] relative"
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter location address"
                      name="workshop_location"
                      placeholder="Workshop Location"
                      className="!min-w-[330px]"
                      raiseInput={setRaiseInput}
                      
                    />
                    {(formik.values.workshop_location.length>1) && <AdressOptions/>}
                    <div className="">
                      <p className="font-bold sharp-sans text-[14px] pb-1">
                        Which kind of service do you provide?
                      </p>
                      <Field name="services_id">
                        {({ field, form }) => (
                          <Select
                            {...field}
                            options={services}
                            placeholder=""
                            styles={customStyles}
                            onChange={(selectedOptions) => {
                              // console.log(selectedOptions);
                              setSelectedService(selectedOptions);
                              form.setFieldValue(
                                field.name,
                                selectedOptions ? selectedOptions.value : null
                              );
                            }}
                            value={selectedService}
                            isSearchable={true}
                            className="!max-w-[330px] max-h-[44px] min-h-[44px]"
                          />
                        )}
                        </Field>
                    </div>
                    <ErrorMessage name="services_id" component={TextError} />
                    <FileInput
                      name="supported_document"
                      imageSrc="Group72.png" // Replace with your image path
                      className="!min-w-[330px]"
                      label="Supporting Document"
                    />
                    <ErrorMessage name="supported_document" component={TextError} />

                    <FileInput
                      name="profile_pic"
                      imageSrc="Group72.png" // Replace with your image path
                      className="!min-w-[330px]"
                      label="Profile Picture (optional)"
                    />
                    <ErrorMessage name="profile_pic" component={TextError} />

                  </div>

                  {/* <ErrorMessage name="terms" component={TextError} /> */}
                  {errorMessage && <TextError>{errorMessage}</TextError>}

                  <div className="pb-5">
                    <Button
                      className="bg-greeen text-white !mt-[30px]"
                      type="submit"
                      disable={!formik.isValid || formik.isSubmitting}
                      loading={loading[LoaderKey.MECHANIC_CREATE_ACCOUNT_STEP3]?.loading}
                    >
                      {formik.isSubmitting ? "Creating..." : "Continue"}
                    </Button>
                    <Link
                      to="/login-user"
                      className="pt-2 text-center flex justify-center font-[500] text-xs"
                    >
                      Already have an account?{" "}
                      <span className="pl-1 text-greeen sharp-sans-bold">
                        Log In
                      </span>
                    </Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MechanicCreateAccount3;
