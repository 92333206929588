



import {  useSearchParams } from "react-router-dom"
import Callz from "./component"


export default function VideoCall(){
    const [searchParams,setSearchParams] = useSearchParams();
    return <div>
        <Callz  mechanicEmail={searchParams.get("email")} mechanicName={searchParams.get("name")} mechanic_id={searchParams.get("mechanic_id")}
         token={searchParams.get("token")}
         carModel={searchParams.get("model")} profile_pic={searchParams.get("image")}  />
    </div>
}