import dayjs from "dayjs";
import { useEffect, useState } from "react";
import placeholder from "../Images/1 1.png";
import { Button } from "@mui/material";
import { Profile } from "iconsax-react";
import axios from "axios";


const Months = ["January","Febuary","March","April","May","June","July","August","September","October","November","Decemeber"]

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 
export default function WalletTransactions(){
    const [transactions,setTransactions]= useState([])

    useEffect(function(){
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
            try{
                axios.get(`${baseUrl}/wallets/wallet-history/`,
                {
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    console.log(" trnasactions data response",response);
                    setTransactions(init=> response.data.data)
                    return response
                }).catch(function(error){
                    console.log(error);
                })
            }catch(error){
                console.log("an error occured while fetching profile data")
            }
        }
    },[])
    return <div className="w-full pt-2">
        <div className="flex w-full justify-between items-center mb-4">
            <p className="text-xl ">Recent Transactions</p>
            <Button style={{color:"rgb(148 163 184)",textTransform:"none"}} className="text-sm text-slate-400">See All</Button >
        </div>
        <Transactions transactions={transactions} />
    </div>
}

function Transactions({transactions}){
    const [processedArray,setProcessedArra] = useState([]);

    function settor(value){
        setProcessedArra(init=>[value,...init])
    }
    useEffect(function(){       
    let sortData = sortTransactions();
     PackageTransactions2(settor,sortData);
    },[transactions]);


    function sortTransactions(){
        const sortedData =  transactions.sort(function(first_date,second_date){
            switch(dayjs(first_date.date_created).isBefore(dayjs(second_date.date_created))){
                case true : return -1
                case false : return 1
                default : return 0
            }
        })
        return sortedData
    }

    function PackageTransactions2(settor,sortedData){
        let newData
        if (sortedData.length ===0 ){
            console.log("no  data to work with");
            return
        }
        else{
            let Trend = sortedData[0].date_created;
            const data = sortedData.filter(function(oneTransaction){
                return dayjs(oneTransaction?.date_created).isSame(dayjs(Trend),"date")
            })
            console.log("generated data",data);
            newData = sortedData?.slice(data?.length);
            PackageTransactions2(settor,newData);
            settor(data );
            return
        }
    }

    return <div className="w-full">
        {
            processedArray.map(function(ADayOTransaction){
                return <DayTransaction dayTransaction={ADayOTransaction}/>
            })
        }
    </div>
}

function DayTransaction({dayTransaction=[]}){
    return <div className="w-full ">
        <p className = " mb-4 text-sm text-slate-500">{`${dayjs(dayTransaction[0]?.date_created).date()} ${Months[dayjs(dayTransaction[0]?.date_created).month()]} ${dayjs(dayTransaction[0]?.date_created).year()}`}</p>
        {
            dayTransaction?.map(function(oneTransaction){
                return <Atransaction aTransaction={oneTransaction}/>
            })
        }
    </div>
}

function Atransaction({aTransaction}){
    const isFund = aTransaction.transaction_type ==="fund";
    const value =()=> new Intl.NumberFormat("en-US",{style:"currency",currency:"NGN",signDisplay:"never"}).format(Number(aTransaction.amount)).replace("NGN","")
    return <div className="grid grid-cols-3 w-full items-center mb-6">
        <div className="flex gap-4 col-span-2 items-center">
            {aTransaction.profile_pic && <div className="w-[3rem] h-[3rem]">
               <img className="rounded-full " src={aTransaction.profile_pic} />
            </div>}
               {!aTransaction.profile_pic && <Profile/>}
        <div>
             <p className=" text-lg text-ellipsis overflow-hidden">{`${aTransaction.user_detail.first_name} ${aTransaction.user_detail.last_name}`}</p>
             <p className="text-sm text-slate-600">{dayjs(aTransaction.date_created).format("HH:mm a")}</p>
        </div>
        </div>
        <p className={`${isFund?"text-green-500":"text-red-500"} font-bold justify-self-end text-ellipsis overflow-hidden`}>{isFund?"+":"-"}{value().slice(0,value().length-3)}</p>

    </div>
}