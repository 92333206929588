import React, { useEffect, useMemo, useRef, useState } from "react";
import { Call,Message,More } from "iconsax-react";
import {MeetingProvider, MeetingConsumer, useMeeting, useParticipant,} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import Controll_bg from "../../Images/call_control_bg.svg";
import SingleChat from "../chat/components/SingleChat";
import axios from "axios";
const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJmMDRmZDM1ZC00MzNlLTQzMmUtOGM0Yi1mNzdiNWIxZDI1YjEiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyMjkxMzI4MSwiZXhwIjoxNzIzNTE4MDgxfQ.9nXOq3YCfhafg9B9CTzQAbpfLpwyhBFbRKk2AmwteH8"

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

async function createMeeting( token ){
    const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
      method: "POST",
      headers: {
        'authorization': `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const { roomId } = await res.json();
    return roomId;
  };
  
export default function Callz({id,mechanicEmail,mechanicName,mechanic_id,token,carModel,profile_pic}){
  const [meetingId, setMeetingId] = useState(null);
  const [notifcationPayload, setnotificationPayload] = useState({id:"",
                                                                owner_name:"",
                                                                meeting_id:'',
                                                                call_token:'',
                                                                owner_fcm:"",
                                                                fcm_token:"",
                                                                is_call:'',
                                                                is_voice:'',
                                                                initiate_id:"",
                                                                owner_image:"",
                                                                email:"",
                                                                model:carModel})
  // const [notifcationPayload, setnotificationPayload] = useState({id:"",model:carModel,email:"",image:profile_pic,name:"",type:"video"})
  const [shouldOpenChat,setShouldOpenChat] = useState(false);

  function openChat(){
    setShouldOpenChat(init=>true);
  }
  function closeChat(){
    setShouldOpenChat(init=>false);
  }
  function onMeetingLeave(){
    setMeetingId(null);
  };

useEffect(function(){
    const meetingId = (id == null) ?  createMeeting(authToken).then(function(response){
      console.log("meeting id gotten");
      return setMeetingId(init=>response);
    }) : setMeetingId(init=>id);
},[]);


// useEffect(function(){
//   const accessToken = localStorage.getItem('authAccessToken');
//   if(accessToken){
//     if (meetingId !== null) {
//           axios.post(`${baseUrl}/calls/initiate/`,{
//             mechanic_id:mechanic_id,
//             room_id:meetingId
//           },{
//             headers:{
//               'Authorization':`Bearer ${accessToken}`,
//               'Content-Type':'application/json'
//             }
//           })
//     } 
//   }
//   console.log(meetingId);
// },[meetingId])

useEffect(function(){
  const userEmail = localStorage.getItem("userEmail");
const firstname = localStorage.getItem("firstName")
const lastname = localStorage.getItem("lastName")
const ownerToken = localStorage.getItem("deviceToken")
const accessToken = localStorage.getItem('authAccessToken');
    if(accessToken){
        axios.get(`${baseUrl}/user/user/${userEmail}/`,{
          headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
          }
        }).then(function(response){
          if(meetingId != null){
            if(userEmail){
              setnotificationPayload(init=>{return {...init,
              id:response?.data?.data?.id,
              owner_name:`${firstname} ${lastname}`,
              meeting_id:meetingId,
              call_token:authToken,
              owner_fcm:ownerToken,
              fcm_token:token,
              is_call:true,
              is_voice:true,
              // initiate_id:call_id,
              owner_image:response?.data?.data?.profile_pic,
              email:userEmail,
            }})
              console.log("notification payload created");
            }
          }
        }).catch(function(error){
          console.log(error);
        })
    }
},[meetingId])

useEffect(function(){
  const serverKey = `key=AAAAFxxOg6A:APA91bHp5mTFxILLXludRVwmubxhkEkX4Txik6aFr3YJrAjNx7_JVVwSFKy0eqemID2MeKvpeORxl1RptqzQ_A77ALeZi12TJDaj33bGMxDIwsihquGrFVqfw3eVZCDbVk9zXp0TLKIg`

  if(notifcationPayload.id !=""){
    console.log("about to create notification payload");
        axios.post("https://fcm.googleapis.com/fcm/send",{
          to:token,
          notification:{
              title:"RequestMechanic",
              body:"Car Consultation"
          },
          data:notifcationPayload
      },{
          headers:{
              'Authorization':serverKey,
              'Content-Type':'application/json'
          }
      }).catch(function(error){
        console.log(error);
      })
  }

},[notifcationPayload])

  return <div className="w-screen h-screen relative" >
             {(authToken !=null && meetingId !=null ) && (<MeetingProvider config={{ meetingId:meetingId, micEnabled: true, webcamEnabled: true, name: mechanicName,}} token={authToken}>
              <Content openChat={openChat} onMeetingLeave={onMeetingLeave}/>
              </MeetingProvider>)}
              {shouldOpenChat && <div className="absolute top-0 left-0 z-[10]  ">
                          <SingleChat handleBack={closeChat} carModel={carModel} mechanicName={mechanicName} profile_pic={profile_pic} mechanicEmail={mechanicEmail}  />
              </div>}
  </div>
} 

function Content({onMeetingLeave,openChat}){
  const {join, participants} = useMeeting();
  useEffect(function(){
    console.log("keys",[...participants.keys()]);
   },[])
  const participantOne = useParticipant([...participants.keys()][0])
  const participantTwo = useParticipant([...participants.keys()][1])

  useEffect(function(){
    join()
  },[])

  return <div className="w-full h-full">
                    <RemoteScreen participantId={participantOne.isLocal ? [...participants.keys()][1] : [...participants.keys()][0]}/>
                    <div className=" w-full h-full absolute top-0 left-0 flex flex-col justify-between">
                        <LocalScreen participantId={participantOne.isLocal ? [...participants.keys()][0] : [...participants.keys()][1]}/>
                        <Controlz openChat={openChat} displayName={participantOne.isLocal?participantTwo.displayName:participantOne.displayName}/>
                    </div>
  </div>
}

function Countdown({stopCount}){
  const [seconds,setSeconds] = useState(0);
  const [minute,setMinute] = useState(0);
  const [hour,setHour] = useState(0);
const asecond = 1000;
const aMinute = asecond * 60;
const anHour = aMinute *60;
let secondsInterval = useRef('')
let minuteInterval = useRef('')
let hourInterval = useRef('')
function doubleFormat(value){

    if (String(value).length < 2) {
      return `0${value}`
    }else return value
  }

  useEffect(function(){
    secondsInterval.current = setInterval(function(){
        setSeconds(init=>{return init >= 59 ? 0:init +1})
    },1000)

    return function(){
      clearInterval(secondsInterval.current);
      
    }
  },[])

  useEffect(function(){
     minuteInterval.current = setInterval(function(){
        setMinute(init=>{return init >= 59 ? 0:init +1}) 
          return function(){
            clearInterval(minuteInterval.current);
            
          }
    },60000);
  },[])

  useEffect(function(){
     hourInterval.current = setInterval(function(){
      setHour(init=>init++)
    },3600000)

    return function(){
      clearInterval(hourInterval.current);
      
    }
  },[])

  useEffect(function(){
    if (stopCount) {
      clearInterval(secondsInterval.current)
      clearInterval(minuteInterval.current)
      clearInterval(hourInterval.current)
    }
  },[stopCount])

  return <div className="w-fit h-fit p-2 bg-slate-300 bg-opacity-60  rounded-lg">
    <p className="text-white">{doubleFormat(hour)}:{doubleFormat(minute)}:{doubleFormat(seconds)}</p>
  </div>
}

function RemoteScreen({participantId}){
  const micRef = useRef(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } = useParticipant(participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current.play().catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

 return <div id="remoteScreen" className="w-full h-full bg-black">
              <audio ref={micRef} autoPlay playsInline muted={isLocal} />
              {webcamOn && <ReactPlayer
                    className="reactVideoPlayer"
                    playsinline 
                    pip={false}
                    light={false}
                    controls={false}
                    muted={false}
                    playing={true}
                    url={videoStream}
                    style={{minHeight:"100%",borderRadius:"0.8rem"}}
                    height={"100%"}
                    width={"auto"}
                    onError={(err) => {
                      console.log(err, "participant video error");
                    }}
                  />}
         </div>
}

function LocalScreen({participantId}){
  // console.log(participantId);

  const micRef = useRef(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } = useParticipant(participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      console.log("web cami s on");
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      console.log("mic ref is not null");
      if (micOn && micStream) {
      console.log("mic  is on and will stream");
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);
  

  return  <div id="localScreen" className="relative top-[5vh] right-[-57vw] w-[35vw] h-[25vh] rounded-lg ">
              <audio ref={micRef} autoPlay playsInline muted={isLocal} />
              <ReactPlayer
                    //
                    className="reactVideoPlayer"
                    playsinline // extremely crucial prop
                    pip={false}
                    light={false}
                    controls={false}
                    muted={true}
                    playing={true}
                    //
                    url={videoStream}
                    //
                    style={{minHeight:"25vh",borderRadius:"0.8rem"}}
                    height={"25vh"}
                    width={"auto"}
                    onError={(err) => {
                      console.log(err, "participant video error");
                    }}
                  />
          </div>
}

function Controlz({displayName,openChat}){
  const [showCountdown,setShowCountdown] = useState(false)
  const [stopCount,setstopCount] = useState(false)
  const { end, toggleMic, toggleWebcam,} = useMeeting({
    onParticipantJoined,
    onMeetingLeft
  });

  function onParticipantJoined(){
    setShowCountdown(init=>true)
  }

  function onMeetingLeft(){
    setstopCount(init=>true)
  }

  return <div id="nameAndControls" className="flex flex-col">
              <div className="relative z-[1] px-4 py-1">
                <p className="text-white text-3xl mb-2">{displayName}</p>
                {showCountdown && <Countdown stopCount={stopCount}/>}
              </div>
              <div className="relative flex flex-col text-white items-center">
                <img src={Controll_bg} className="absolute z-[0] bottom-0 left-0 w-full"/>
                <div className=" relative top-[10%] w-fit h-fit p-8 rounded-full bg-red-700">
                      <Call onClick = {()=>{end(); window.history.back()}}className="z-[1]"/>
                </div>
                <div className="z-[1] relative w-full flex justify-between px-4 py-2 items-baseline">
                  <div onClick={openChat} className="w-fit h-fit bg-white rounded-2xl p-3">
                    <Message variant="Bold" className="text-green-600"/>
                  </div>
                  <p className="text-sm text-slate-200">your call  is secure</p>
                  <div className="w-fit h-fit  bg-slate-200 bg-opacity-30 rounded-2xl p-3">
                    <More className="rotate-90"/>
                  </div>
                </div>
              </div>
</div>
}