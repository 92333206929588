import { LoaderKey } from "../../loaderKey";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "./utils/errorHandler";
import UserService from "../../service/userService";


export const userCreateAccountStep1 = createAsyncThunk(
    "create/userAccountStep1",
    async (payload, { dispatch }) => {
      dispatch(startLoad(LoaderKey.USER_CREATE_ACCOUNT_STEP1));
      try {
        const res = await UserService.userCreateAccountStep1(payload.data);
        // console.log('profile 1 submitted succesfully from the action')
        return res;
      } catch (err) {
        if (payload.onError) {
          payload.onError(err);
        }
        handleError(err, LoaderKey.USER_CREATE_ACCOUNT_STEP1, dispatch);
        
      } finally {
        // stop loading eventually
        dispatch(stopLoad(LoaderKey.USER_CREATE_ACCOUNT_STEP1));
      }
    }

);


export const getCarBrands = createAsyncThunk(
  "get/CarBrands",
  async (_, { dispatch }) => {
    dispatch(startLoad(LoaderKey.GET_CAR_BRANDS));
    try {
      const res = await UserService.getCarBrands();
      // console.log(res)
      return res;
    } catch (err) {
      handleError(err, LoaderKey.GET_CAR_BRANDS, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad(LoaderKey.GET_CAR_BRANDS));
    }
  }
);

export const getMechanicServices = createAsyncThunk(
  "get/mechanicServices",
  async (_, { dispatch }) => {
    dispatch(startLoad(LoaderKey.GET_MECHANIC_SERVICES));
    try {
      const res = await UserService.getMechanicServices();
      // console.log(res)
      return res;
    } catch (err) {
      handleError(err, LoaderKey.GET_CAR_BRANDS, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad(LoaderKey.GET_MECHANIC_SERVICES));
    }
  }
);




export const userCreateAccountStep2 = createAsyncThunk(
  "create/userAccountStep2",
  async ({ data, accessToken }, { dispatch }) => {
    dispatch(startLoad(LoaderKey.USER_CREATE_ACCOUNT_STEP2));
    try {
      const res = await UserService.userCreateAccountStep2(data, accessToken);
      if (res.success === true) {
        // console.log('profile 2 submitted succesfully from the action')
      }
      return res;
    } catch (err) {
      handleError(err, LoaderKey.USER_CREATE_ACCOUNT_STEP2, dispatch);
      throw { success: false, message: err.response?.data?.message || err.message };
    } finally {
      // stop loading eventually
      dispatch(stopLoad(LoaderKey.USER_CREATE_ACCOUNT_STEP2));
    }
  }

);

export const carOwnerCreateAccountStep3 = createAsyncThunk(
  "carOwner/createAccountStep3",
  async ({ data, accessToken }, { dispatch }) => {
    dispatch(startLoad(LoaderKey.CAR_OWNER_CREATE_ACCOUNT_STEP3));
    try {
      const res = await UserService.carOwnerCreateAccountStep3(data, accessToken);
      if (res.success === true) {
        // console.log('car owner account has fully been created successfully')
      }
      return res;
    } catch (err) {
      handleError(err, LoaderKey.CAR_OWNER_CREATE_ACCOUNT_STEP3, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad(LoaderKey.CAR_OWNER_CREATE_ACCOUNT_STEP3));
    }
  }

);

// export const mechanicCreateAccountStep3 = createAsyncThunk(
//   "mechanic/createAccountStep3",
//   async ({ data, accessToken }, { dispatch }) => {
//     dispatch(startLoad(LoaderKey.MECHANIC_CREATE_ACCOUNT_STEP3));
//     try {
//       const parseData = data.car_speciality_ids.map(function(aSpecialty){
//         return aSpecialty.value
//       })
//       const newData = {...data,car_speciality_ids:parseData}
//       console.log(newData);
//       const res = await UserService.mechanicCreateAccountStep3(newData, accessToken);
//       if (res.success === true) {
//         // console.log('mechanics account has fully been created successfully')
//       }
//       return res;
//     } catch (err) {
//       handleError(err, LoaderKey.MECHANIC_CREATE_ACCOUNT_STEP3, dispatch);
//     } finally {
//       // stop loading eventually
//       dispatch(stopLoad(LoaderKey.MECHANIC_CREATE_ACCOUNT_STEP3));
//     }
//   }

// );
export const mechanicCreateAccountStep3 = createAsyncThunk(
  "mechanic/createAccountStep3",
  async ({ data, accessToken }, { dispatch }) => {
    dispatch(startLoad(LoaderKey.MECHANIC_CREATE_ACCOUNT_STEP3));
    try {
      const parseData = data.car_speciality_ids.map(function(aSpecialty){
        return aSpecialty.value
      })
      const newData = {...data,car_speciality_ids:JSON.stringify(parseData)}
      console.log(newData);
      const res = await UserService.mechanicCreateAccountStep3(newData, accessToken);
      if (res.success === true) {
        // console.log('mechanics account has fully been created successfully')
      }
      return res;
    } catch (err) {
      handleError(err, LoaderKey.MECHANIC_CREATE_ACCOUNT_STEP3, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad(LoaderKey.MECHANIC_CREATE_ACCOUNT_STEP3));
    }
  }

);
export const updateProfilePicture = createAsyncThunk(
  "update/profilePicture",
  async ({ data, accessToken }, { dispatch }) => {
    try {
      const res = await UserService.updateProfilePicture(data, accessToken);
      if (res.success === true) {
        // console.log('mechanics account has fully been created successfully')
      }
      return res;
    } catch (err) {
      handleError(err, dispatch);
    }
  }

);

export const resetPassword = createAsyncThunk(
  "create/resetPassword",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoad(LoaderKey.USER_RESET_PASSWORD));
    try {
      const res = await UserService.resetPassword(payload.data);
      // console.log('password reset successfully')
      return res;
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : err.message);
      // handleError(err, LoaderKey.USER_RESET_PASSWORD, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad(LoaderKey.USER_RESET_PASSWORD));
    }
  }

);

export const setNewPassword = createAsyncThunk(
  "create/setNewPassword",
  async (payload, { dispatch }) => {
    dispatch(startLoad(LoaderKey.USER_SET_NEW_PASSWORD));
    try {
      const res = await UserService.setNewPassword(payload.data);
      // console.log('new password set successfully')
      return res;
    } catch (err) {
      handleError(err, LoaderKey.USER_SET_NEW_PASSWORD, dispatch);
      throw { success: false, message: err.response?.data?.message || err.message };
    } finally {
      // stop loading eventually
      dispatch(stopLoad(LoaderKey.USER_SET_NEW_PASSWORD));
      
    }
  }

);
