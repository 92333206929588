import { useState, useEffect } from "react";
import { Title } from "../../Components/shared/Title";
import styled from "styled-components";
import { ReactComponent as Bell } from "../../assets/icons/notification.icon.svg";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../../Components/NavBar";
import axios from "axios";
import { RequestLists } from "./components/RequestLists";

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

const MechanicDashboard = () => {
  const [firstName, setfirstName] = useState();
  const [lastName, setLastName] = useState();
  const [image, setImage] = useState();
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // retieving the first and last name from local storage
    const first_name =
      localStorage.getItem("firstName") || sessionStorage.getItem("firstName");
    const last_name =
      localStorage.getItem("lastName") || sessionStorage.getItem("lastName");

    setfirstName(first_name);
    setLastName(last_name);
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("authAccessToken");
    const userEmail = localStorage.getItem("userEmail");
    axios
      .get(`${baseUrl}/user/user/${userEmail}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setBrands(response.data.data.car_speciality);
        setImage((init) => response.data.data.profile_pic);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  useEffect(function(){
    window.addEventListener("audio-call",function(){
      alert("call incoming");
    })
    window.addEventListener("info",function(){
      alert("call incoming");
    })
  },[])

  return (
    <MechanicView>
      <div className="user-info">
        <div className="info">
          <div className="img-wrapper">
            <img src={image} alt="mechanic" />
          </div>
          <div className="text">
            <p className="bold">Welcome back, {firstName}</p>
            <p className="subtitle">Check out your incoming requests</p>
          </div>
        </div>

        <Bell />
      </div>
      <Title text={"Available Requests"} />
      <div className="tabs">
        {brands?.map((brand) => {
          return (
            <div
              key={brand.id}
              className="tab"
            >
              {brand.name}
            </div>
          );
        })}
      </div>
      <RequestLists />
      <div className="bottom">
        <NavBar />
      </div>
    </MechanicView>
  );
};

export default MechanicDashboard;

const MechanicView = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  position: relative;

  .bottom {
    margin-top: 2rem;
    height: 5rem;
    padding: 20px 0;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 100;
    background-color: #ffffff;
    border-top: 1px solid #dedede;
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 1rem; */

    .info {
      display: flex;
      align-items: center;
      gap: 1rem;

      .img-wrapper {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .bold {
          font-weight: 600;
          font-size: 1rem;
        }

        .subtitle {
          font-size: 0.8rem;
          color: #878c98;
        }
      }
    }
  }

  .tabs {
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 1rem 0;

    .tab {
      font-size: 14px;
      border-radius: 20px;
      padding: 0.3em 1.2rem;
      background-color: #EAFFF8;
    }
  }
`;
