import * as Yup from "yup";
import Button from '../Components/button/index';
import { Form, Formik } from "formik";
import FormikControl from "../Components/formik/formikControl";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoaderKey } from "../Components/loaderKey";
import { useEffect, useState } from "react";
import { TextError } from "../Components/utils";
import { resetPassword, setNewPassword } from "../Components/store/actions/userAction";


const ResetPassword = () => {
    // const navigate = useNavigate(); // Initialize useNavigate hook
    const dispatch = useDispatch();  //Initialize useDispatch hook
    const loading = useSelector((state) =>state.app);

    const [errorMessage, setErrorMessage] = useState('');
    const [finished, setFinished] = useState(false);
    const [finishedProcess, setFinishedProcess] = useState(false);
    const [email, setEmail] = useState('')


    const initialValues = {
        email: "",
    };
    const initialValues2 = {
        otp_code: "",
        password: "",
    }

    const onSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            const res = await dispatch(resetPassword({data:values}));
            if (res.payload.success === true){
                localStorage.setItem("emailResetPassword", values.email);

                // console.log('password reset successful')
                setFinished(true);
            } 
            
        } catch (err) {
            console.error('Password reset failed:', err);
            setErrorMessage('Failed to reset password')
             // Log error message
          } finally {
            setSubmitting(false); // Reset submitting state in Formik
          }
        
    };
    
    const onSubmit2 = async (values, {setSubmitting}) => {
        try{
            const dataValues = {
                email,
                ...values
            }
            const res = await dispatch(setNewPassword({data:dataValues}));
            if (res.payload.success === true) {
                // console.log('new password has been set successfully');
                setFinishedProcess(true);
                // setErrorMessage('Failed to reset password')
            }
        } catch (err) {
            console.error('New Password reset failed!:', err);
            setErrorMessage("Invalid OTP")
        } finally {
            setSubmitting(false); // Reset submitting state in Formik
        }
    }

    useEffect(() =>{
        const storedEmail = localStorage.getItem('emailResetPassword')
        setEmail(storedEmail)
    }, [])
      
    const validationSchema = Yup.object({
        email: Yup.string()
          .email("Invalid email format")
          .required("Required"),
    });
    const validationsSchema2 = Yup.object({
        password: Yup.string().required("Required"),
        otp_code: Yup.string().required("Required")

    })

    return (
        <div className="sharp-sans flex flex-col justify-center items-center">
            <div className="mt-10">
                {finishedProcess ? <p className="sharp-sans text-center text-[14px]">Congratulations, your password has been reset successfully!, <Link to="/login-user" className="text-greeen sharp-sans-bold font-[500]">please login to your account</Link></p>
                
            : 
            <div className="space-y-1">
                <h3 className="sharp-sans-bold text-2xl">
                    {finished ? "Reset Password" : "Forgot Password"}
                </h3>
                <p className="sharp-sans text-xs font-medium text-[#878C98]">
                    {finished ? <div>Check your email for your OTP to set your new password</div> : <div>Enter your email address to receive an OTP to reset<br/> your password</div>}
                    
                </p>
            </div>
        }
                <div className="mt-10">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik) => {
                            return (
                                <Form className={`${finished ? "hidden" :"block"} space-y-[20px]`}>
                                    <FormikControl
                                        control="input"
                                        type="email"
                                        label="Email address"
                                        name="email"
                                        placeholder="Enter your email address"
                                        className="!min-w-[330px]"
                                    />
                                    {errorMessage && <TextError>{errorMessage}</TextError>}
                                    <div className="pt-[5px]">
                                        <Button
                                            className="!min-w-[330px] bg-greeen text-white !mt-[30px]"
                                            type="submit"
                                            disable={!formik.isValid || formik.isSubmitting}
                                            loading={loading[LoaderKey.USER_RESET_PASSWORD]?.loading}
                                        >
                                            {formik.isSubmitting ? 'Continue' : 'Continue'}
                                        </Button>
                                    </div>
                                </Form>
                            )
                        }}
                        
                    </Formik>
                    
                </div>
                
               {finished && 
                    <div className="mt-8">
                        <Formik
                            initialValues={initialValues2}
                            validationSchema={validationsSchema2}
                            onSubmit={onSubmit2}
                        >
                            {(formik) => {
                            return (
                                <Form className={`${finishedProcess ? "hidden" : "flex"}  flex-col justify-between h-[490px]`}>
                                    <div className="space-y-8">
                                        <FormikControl
                                        control="input"
                                        type="text"
                                        label="Enter the otp code sent to your email"
                                        name="otp_code"
                                        placeholder=""
                                        className="!min-w-[345px]"
                                        />
                                        <FormikControl
                                        control="input"
                                        type="password"
                                        label="Enter your new password"
                                        name="password"
                                        placeholder="Enter your new password"
                                        className="!min-w-[345px]"
                                        />
                                        {errorMessage && <TextError>{errorMessage}</TextError>}
                                    </div>

                                    {/* <ErrorMessage name="terms" component={TextError} /> */}

                                    <div className="pb-5">
    
                                        <Button
                                        className="bg-greeen text-white !mt-[30px]"
                                        type="submit"
                                        disable={!formik.isValid || formik.isSubmitting}
                                        loading={loading[LoaderKey.USER_SET_NEW_PASSWORD]?.loading}
                                        >
                                        {formik.isSubmitting ? "Continue" : "Continue"}
                                        </Button>
                                    </div>
                                </Form>
                            );
                            }}
                        </Formik>
                    </div>
                }
               
            </div>
        </div>
    );
}
 
export default ResetPassword;