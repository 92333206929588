export const CustomerTnC = () => {
    return (
      <>
        <h3 className="text-sm font-semibold">General Terms</h3>
        <p className="mb-3 text-red-700">
          By accessing and placing an order with REQUESTMECHANIC.COM, you confirm
          that you are in agreement with and bound by the terms of service
          contained in the Terms & Conditions outlined below. These terms apply to
          the entire website and any email or other type of communication between
          you and REQUESTMECHANIC.COM.
        </p>
  
        <h3 className="text-sm font-semibold">Quality Assurance</h3>
        <p>
          For calls: REQUESTMECHANIC ensures the quality of calls, connections,
          and services by implementing guidelines and measures to monitor the
          conduct of its auto experts. To ensure call quality, we record every
          call made on the platform, providing necessary information for dispute
          resolution. Additionally, we offer a 5-star rating system for users to
          rate their connections, allowing us to address low-quality experts.
          REQUESTMECHANIC also provides a replacement call if an auto expert
          behaves unprofessionally. For servicing: In the event that a user is
          unable to service their car or experiences damage due to one of our
          connected mechanics, resulting in wasted time and funds, REQUESTMECHANIC
          will address the situation by:
        </p>
        <ul className="list-disc ml-5">
          <li>Providing a better auto expert for the service.</li>
          <li> Refunding the cost of the failed service.</li>
          <li>Covering the repair costs for damage caused by our mechanic.</li>
        </ul>
        <p className="mb-3">
          By accessing and placing an order with REQUESTMECHANIC.COM, you confirm
          that you agree to be bound by the terms of service contained in the
          Terms & Conditions outlined below. These terms apply to the entire
          website and any communication between you and REQUESTMECHANIC.COM.
        </p>
        <p className="mb-3">
          Under no circumstances shall REQUESTMECHANIC.COM or its team be liable
          for any direct, indirect, special, incidental, or consequential damages,
          including loss of data or profit, arising from the use or inability to
          use the materials on this site, even if notified of the possibility of
          such damages. If your use of materials from this site requires
          servicing, repair, or correction of equipment or data, you assume any
          associated costs.
        </p>
        <p className="mb-3">
          REQUESTMECHANIC.COM will not be responsible for any outcomes that may
          occur during the usage of our resources. We reserve the right to change
          prices and revise the resources usage policy at any time.
        </p>
  
        <h3 className="text-sm font-semibold">License</h3>
        <p className="mb-3">
          REQUESTMECHANIC.COM grants you a revocable, non-exclusive,
          non-transferable, limited license to download, install, and use the
          website/app strictly in accordance with the terms of this Agreement. By
          using the REQUESTMECHANIC Service, you agree to be bound by these Terms
          & Conditions.
        </p>
  
        <h3 className="text-sm font-semibold">Restrictions</h3>
        <p className="mb-3">
          You agree not to license, sell, rent, lease, assign, distribute,
          transmit, host, outsource, disclose, or otherwise commercially exploit
          the website/app or make it available to any third party. You also agree
          not to modify, make derivative works of, disassemble, decrypt, reverse
          compile, or reverse engineer any part of the website/app.
        </p>
  
        <h3 className="text-sm font-semibold">Your Suggestions</h3>
        <p className="mb-3">
          Any feedback, comments, ideas, improvements, or suggestions provided by
          you to REQUESTMECHANIC regarding the website/app shall remain the sole
          and exclusive property of REQUESTMECHANIC. REQUESTMECHANIC may use,
          copy, modify, publish, or redistribute the suggestions without providing
          credit or compensation to you.
        </p>
  
        <h3 className="text-sm font-semibold">Your Consent</h3>
        <p className="mb-3">
          By using our website/app, registering an account, or making a purchase,
          you consent to our Terms & Conditions.
        </p>
  
        <h3 className="text-sm font-semibold">Membership and Registration</h3>
        <p className="mb-3">
          Our services are available only to individuals who are 18 years and
          older and who can form legally binding contracts under applicable law.
          By registering for an account and using our services, you confirm that
          you are at least 18 years old and that all registration information you
          submit is accurate and truthful.
        </p>
        <p className="mb-3">
          Users who meet the eligibility criteria stated above must create an
          account by providing the following information:
          <ul className="list-disc ml-5">
            <li className="ml-5">Full name</li>
            <li className="ml-5">Email address</li>
            <li className="ml-5">
              Telephone number (for contacting in case of contracts or delivery of
              automotive services being offered)
            </li>
            <li className="ml-5">
              Car information (for matching with the correct automotive
              specialists)
            </li>
            <li className="ml-5">Physical address</li>
            <li className="ml-5">Date of Birth (for age restriction purposes)</li>
          </ul>
        </p>
        <p className="mb-3">
          At this point, you are required to confirm your agreement with our terms
          of use and privacy policy by ticking a box. A one-time password will be
          provided to you upon signing up.
        </p>
        <p className="mb-3">
          This information is collected to facilitate your access to our
          automotive consultation services and ensure that our platform operates
          effectively. It helps us match you with the appropriate automotive
          specialists, verify your identity, and provide accurate and personalized
          services.
        </p>
  
        <h3 className="text-sm font-semibold">Personal Data</h3>
        <p className="mb-3">
          The company collects and processes users’ personal data. For further
          details, every user is encouraged to read the Platform’s “Privacy
          Policy” . For the avoidance of doubt, with the exception of our payment
          provider, we do not share your details with any other third parties.
        </p>
        <h3 className="text-sm font-semibold">Links to Other Websites</h3>
        <p className="mb-3">
          This Terms & Conditions applies only to the Services. The Services may
          contain links to other websites not operated or controlled by
          REQUESTMECHANIC. We are not responsible for the content, accuracy, or
          opinions expressed on such websites. Your browsing and interaction on
          any other website are subject to that website's own rules and policies.
        </p>
  
        <h3 className="text-sm font-semibold">Cookies</h3>
        <p className="mb-3">
          REQUESTMECHANIC uses cookies to identify the areas of our website that
          you have visited. These cookies enhance the performance and
          functionality of our website/app but are non-essential to their use.
          However, disabling cookies may limit access to certain functionality. We
          never place Personally Identifiable Information in Cookies.
        </p>
  
        <h3 className="text-sm font-semibold">
          Changes to Our Terms & Conditions
        </h3>
        <p className="mb-3">
          If we decide to change our Terms & Conditions, we will post those
          changes on this page and/or update the modification date below.
        </p>
  
        <h3 className="text-sm font-semibold">
          Modifications to Our website/app
        </h3>
        <p className="mb-3">
          REQUESTMECHANIC reserves the right to modify, suspend, or discontinue
          the website/app or any service connected to it, with or without notice
          and without liability to you.
        </p>
  
        <h3 className="text-sm font-semibold">Updates to Our website/app</h3>
        <p className="mb-3">
          REQUESTMECHANIC may provide enhancements or improvements to the
          website/app's features/functionality, including patches, bug fixes,
          updates, upgrades, and other modifications. All updates will be
          considered integral parts of the website/app and subject to the terms of
          this Agreement.
        </p>
  
        <h3 className="text-sm font-semibold">Third-Party Services</h3>
        <p className="mb-3">
          We may display, include, or make available third-party content or
          provide links to third-party websites or services. REQUESTMECHANIC is
          not responsible for the accuracy, completeness, or quality of
          Third-Party Services, and accessing them is at your own risk.
        </p>
  
        <h3 className="text-sm font-semibold">Term and Termination</h3>
        <p className="mb-3">
          This Agreement remains in effect until terminated by you or
          REQUESTMECHANIC. REQUESTMECHANIC may suspend or terminate this Agreement
          at any time without prior notice. Termination of this Agreement does not
          limit REQUESTMECHANIC’s rights or remedies.
        </p>
  
        <h3 className="text-sm font-semibold">Copyright Infringement Notice</h3>
        <p className="mb-3">
          If you believe any material on our website/app infringes on your
          copyright, please contact us with specific information. REQUESTMECHANIC
          will act in accordance with applicable laws regarding copyright
          infringement.
        </p>
  
        <h3 className="text-sm font-semibold">Indemnification</h3>
        <p className="mb-3">
          You agree to indemnify and hold REQUESTMECHANIC harmless from any claim
          or demand arising from your use of the website/app or violation of this
          Agreement or any law.
        </p>
  
        <h3 className="text-sm font-semibold">No Warranties</h3>
        <p className="mb-3">
          The website/app is provided "AS IS" and "AS AVAILABLE" without
          warranties of any kind. REQUESTMECHANIC disclaims all warranties,
          including implied warranties of merchantability, fitness for a
          particular purpose, title, and non-infringement.
        </p>
  
        <h3 className="text-sm font-semibold">Limitation of Liability</h3>
        <p className="mb-3">
          REQUESTMECHANIC's liability for any damages arising from the use of the
          website/app is limited to the amount paid by you for the website/app.
          REQUESTMECHANIC is not liable for any special, indirect, or incidental
          damages.
        </p>
  
        <h3 className="text-sm font-semibold">Severability</h3>
        <p className="mb-3">
          If any provision of this Agreement is unenforceable, the remaining
          provisions will remain in full force and effect. This Agreement
          constitutes the entire agreement between you and REQUESTMECHANIC.
        </p>
  
        <h3 className="text-sm font-semibold">Waiver</h3>
        <p className="mb-3">
          Failure to exercise a right under this Agreement does not waive that
          right. No waiver of breach constitutes a waiver of subsequent breaches.
        </p>
  
        <h3 className="text-sm font-semibold">Amendments to this Agreement</h3>
        <p className="mb-3">
          REQUESTMECHANIC may modify this Agreement at any time. By continuing to
          use the website/app after revisions, you agree to the updated terms.
        </p>
  
        <h3 className="text-sm font-semibold">Entire Agreement</h3>
        <p className="mb-3">
          This Agreement supersedes all prior agreements between you and
          REQUESTMECHANIC regarding the website/app.
        </p>
  
        <h3 className="text-sm font-semibold">Updates to Our Terms</h3>
        <p className="mb-3">
          We may update our Terms to reflect changes in our Service and policies.
          By continuing to use the Service, you agree to the updated Terms.
        </p>
  
        <h3 className="text-sm font-semibold">Intellectual Property</h3>
        <p className="mb-3">
          The website/app and its contents are owned by REQUESTMECHANIC and
          protected by copyright and other intellectual property laws.
          Unauthorized use of the material is prohibited.
        </p>
  
        <h3 className="text-sm font-semibold">Agreement to Arbitrate</h3>
        <p className="mb-3">
          Disputes between you and REQUESTMECHANIC will be resolved through
          binding arbitration, except for disputes related to intellectual
          property rights.
        </p>
  
        <h3 className="text-sm font-semibold">Submissions and Privacy</h3>
        <p className="mb-3">
          Submissions to REQUESTMECHANIC become its property, and REQUESTMECHANIC
          may use them without compensation to you.
        </p>
  
        <h3 className="text-sm font-semibold">Promotions</h3>
        <p className="mb-3">
          Promotions offered by REQUESTMECHANIC may be governed by separate rules.
          You agree to comply with all Promotion rules.
        </p>
  
        <h3 className="text-sm font-semibold">Typographical Errors</h3>
        <p className="mb-3">
          REQUESTMECHANIC reserves the right to refuse or cancel orders due to
          incorrect pricing or information errors.
        </p>
  
        <h3 className="text-sm font-semibold">Miscellaneous</h3>
        <p className="mb-3">
          If any provision of these Terms is unenforceable, the remainder will
          remain in full force. These Terms govern your use of the website/app and
          supersede all prior agreements.
        </p>
  
        <h3 className="text-sm font-semibold">Disclaimer</h3>
        <p className="mb-3">
          REQUESTMECHANIC is not responsible for the accuracy or completeness of
          content on the website/app. We do not provide warranties or guarantees,
          and we may make changes to the content without prior notice.
          REQUESTMECHANIC disclaims all warranties and representations regarding
          the content, services, and products provided through or accessible via
          the website/app.
        </p>
  
        <h3 className="text-sm font-semibold">Contact Us</h3>
        <p className="mb-3">
          If you have any questions or concerns about these Terms & Conditions,
          please don't hesitate to contact us.
        </p>
  
        <p className="mb-3">
          By accessing or using the REQUESTMECHANIC Service, you agree to abide by
          these Terms & Conditions. If you do not agree with any part of these
          Terms, please refrain from using the Service.
        </p>
  
        <p>Thank you for using REQUESTMECHANIC.COM</p>
      </>
    )
  };